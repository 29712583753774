.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;

  h2 {
    margin-bottom: 1rem;
  }

  label {
    display: block;
    width: 100%;
    margin-top: 1rem;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .forgotten-password {
    margin-top: 1rem;
    color: #1a8c72;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: #93b24f;
    }
  }
}
