.AppConfigAdministration {
  & h3 {
    font-size: 20px;
    display: flex;
    align-items: center;

    & .Loading {
      margin: 0 8px
    }
  }
}