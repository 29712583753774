.course-modal-modal-body {
  text-align: left;
}

.course-modal-modal-body-course-date .button-wrapper {
  display: inline-flex;
  margin-left: auto;
}

.course-modal-modal-body-course-date .form-group {
  width: 20%;
  display: inline-block;
}

.course-modal-modal-body-course-date-list {
  display: flex;
  margin-bottom: 8px;
}

.course-modal-modal-body-course-date-header {
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid gray;
}
