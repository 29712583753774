.FormInput.disabled input {
  cursor: not-allowed;
  background-color: rgba(164, 164, 164, .2);
}

.FormInput.required label::before {
  content: '*';
  color: red;
  position: relative;
  display: inline-block;
}

.Icon {
  cursor: pointer;
  width: 2rem;
  display: inline-block;
}

.FormInput {
  margin-bottom: 1rem;

  & label {
    margin-bottom: 0.5rem;
    line-height: 24px;
    display: block;
  }

  & input, textarea {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
  }
}