.EmailAdministration {
  & .editor {
    margin-bottom: 2rem;
  }

  & .submenu {
    font-size: 20px;
    margin-bottom: 2rem;

    & .item {
      padding: 6px 12px;
      border-radius: 12px;

      &.selected {
        background-color: #b1e069;
      }
    }
  }

  & .wrapperClassName {
    border: 1px solid gray;
  }
}