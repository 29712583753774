.FormsArrayOf_row {
    display: flex;
    justify-content: space-between; /* pokud chcete mezi prvky určitý prostor */
    align-items: center; /* pokud chcete prvky vertikálně centrovat */
    gap: 10px;
}


.FormsArrayOf {
    margin: 2rem 0;
}