// colors
$primary-color: #1a8c72;
$secondary-color: #93b24f;
$breakpoint-tablet: 768px;
$gradient: linear-gradient(90deg, $primary-color, $secondary-color);

// inputs
@mixin input-styles {
  font-size: 1rem;
  padding: 0.5rem 1.2rem;
  border-radius: .4rem;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  white-space: nowrap;
  min-width: 8rem;

  &:hover {
    opacity: 0.9;
  }
}

// resolutions
$max-width: 1280px;

// media query
$breakpoint-tablet: 768px;
$media-tablet: "only screen and (max-width: #{$breakpoint-tablet})";