.AdministrationLinks {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  font-size: 18px;

  & .active-background {
    border-radius: 12px;
    position: absolute;
    height: 45px;
    background-color: #93b24f;
    color: white;
    z-index: -1;
    transition: left 0.5s ease, width 0.5s ease; /* Animace přechodu */
  }

  & a {
    text-decoration: none;
    padding: 8px;
    color: black;
    border-radius: 12px;
    transition: color 0.5s;

    &.selected {
      color: white;
      transition: color 0.05s 0.3s;

    }
  }
}