.UserDetailModalContent {
  font-size: 14px;
  font-weight: 400;

  .course-row {
    margin-bottom: .5rem;

    & label {
      padding: 4px 0;
    }
  }

  & .passed {
    background-color: rgba(0, 255, 0, .2);
  }

  & .failed {
    background-color: rgba(255, 0, 0, .2);
  }

  & h3 {
    font-size: 1.3rem;
    border-bottom: 1px solid rgba(1, 1, 1, .2);
    width: 100%;
    margin: 1rem 0;
    text-align: center;
  }

  & .user-info {
    label {
      font-size: 18px;
    }

    & .row {

      & .info {
        display: flex;
        flex-direction: column;

        & .label {
          margin-bottom: .5rem;
        }

        & .value {
          font-size: 1rem;
        }
      }
    }

    label {
      font-weight: 600;
    }
  }


  & .test-info > div > div > label:nth-child(1),
  & .test-info > div > div > label:nth-child(2) {
    width: 15%;
  }

  & .test-info > div > div > label:nth-child(3),
  & .test-info > div > div > label:nth-child(4) {
    width: 20%;
  }

  & .test-info > div > div > label:nth-child(5),
  & .test-info > div > div > label:nth-child(6) {
    width: 15%;
  }

  & .course-info > div > label:nth-child(1) {
    font-weight: bold;
    width: 8%;
  }

  & .course-info > div > label:nth-child(2) {
    width: 15%;
  }

  & .course-info > div > label:nth-child(3),
  & .course-info > div > label:nth-child(4),
  & .course-info > div > label:nth-child(5) {
    width: 9%;
  }

  & .course-info > div > label:nth-child(6) {
    width: 14%;
  }

  & .course-info > div > label:nth-child(7) {
    width: 6%;
  }

  & .course-info > div > label:nth-child(8) {
    width: 12%;
  }

  & .course-info > div > label:nth-child(9) {
    width: 12%;
  }

  & .course-info > div > label:nth-child(10) {
    width: 6%;
  }

  .reset-link {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .other-test td {
    width: 150px;
  }

}

.other-test-form .container {
  display: inline-flex;
}