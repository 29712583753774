.dashboard-charts {
  max-width: 95%;
  margin: auto;
  position: relative;
}

.dashboard-charts > div {
  position: relative;
}

.dashboard-charts * {
  margin: 0;
  padding: 0;
}

.dashboard-charts-level-points-bar {
  position: absolute;
}

.dashboard-charts-level-points-bar > label {
  position: absolute;
}

.dashboard-charts-level-points-bar > label {
  height: 40px;
  top: 24px;
  display: flex;
  align-items: flex-end;
}

.dashboard-charts-level-points-bar > label:nth-of-type(1) {
  left: 0;
  padding-left: 2px;
  border-left: 2px solid;
}

.dashboard-charts-level-points-bar > label:nth-of-type(2) {
  right: 0;
  padding-right: 2px;
  border-right: 2px solid;
}

.dashboard-charts-level-points-bar > div {
  height: 16px;
  border-radius: 8px;
}

.dashboard-charts-occupancy-chart {
  margin-top: 24px;
}
