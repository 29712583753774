.app-config-modal-modal-body {
  text-align: left;
}

.app-config-modal-modal-body h5 {
  text-align: center;
  margin: 0;
  line-height: 1;
}

.app-config-modal-modal-body h4 {
  border-bottom: 1px solid gray;
  margin-top: 12px;
}

.app-config-modal-modal-body-level-input {
  width: 33%;
}
