.CertificateAdministration {
  & .react-pdf__Page__textContent.textLayer {
    display: none;
  }

  & .react-pdf__Page__annotations.annotationLayer {
    display: none;
  }

  & .control-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}