.AssignPaymentEditor {
  position: fixed;
  inset: 0;
  display: flex;
  place-items: center;
  z-index: 5;
  background: rgba(0, 0, 0, 0.1);

  & > dialog {

    & > input {
      width: 100%;
    }

    & > menu {
      text-align: end;
    }
  }
}