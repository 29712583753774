.button-wrapper .spinner-border {
  margin-right: 8px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #1a8c72 0%, #93b24f 100%);
  border: 0;
  color: #ffffff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  min-height: 34px;
  min-width: 128px;
}

.button-wrapper.inline {
  display: inline-block;
}

.button-wrapper.dark {
  background: darkcyan;
}
