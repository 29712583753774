
.PeriodAdministration-wrapper {
  padding: 0 32px;

  & h3 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 2rem;
  }

  //& table {
  //  width: 100%;
  //
  //  & tr {
  //
  //
  //    & th, td {
  //      padding: 0.5rem;
  //      vertical-align: top;
  //      border-top: 1px solid #dee2e6;
  //    }
  //  }
  //}
}

.PeriodAdministration-wrapper > .btn {
  margin-top: 16px;
  margin-left: auto;
}

.PeriodAdministration-wrapper-chart > div {
  position: relative;
}

.PeriodAdministration-wrapper-chart * {
  margin: 0;
  padding: 0;
}

.PeriodAdministration-wrapper-chart > label {
  position: absolute;
  top: 0;
}

.PeriodAdministration-wrapper-chart > label:first-child {
  left: 0;
}

.PeriodAdministration-wrapper-chart > label:last-child {
  right: 0;
}


.PeriodAdministration-wrapper-chart-period-bar {
  position: absolute;
}

.PeriodAdministration-wrapper-chart-period-bar > label {
  height: 40px;
  top: 24px;
  display: flex;
  align-items: flex-end;
  position: absolute;
}

.PeriodAdministration-wrapper-chart-period-bar > label:nth-of-type(1) {
  left: 0;
  padding-left: 2px;
  border-left: 2px solid;
}

.PeriodAdministration-wrapper-chart-period-bar > label:nth-of-type(2) {
  right: 0;
  padding-right: 2px;
  border-right: 2px solid;
}

.PeriodAdministration-wrapper-chart-period-bar > div {
  height: 16px;
  border-radius: 8px;
}

.PeriodAdministration-wrapper {
  & .pdfForm {
    margin-top: 2rem;

    & .row {
      margin-bottom: 1rem;
      display: flex;
    }
  }
}