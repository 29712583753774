.FormSelect {
  position: relative;

  & .empty__option {
    min-height: 40px;
  }

  &.disabled .dropdown__selection {
    cursor: not-allowed;
  }

  label {
    margin-bottom: 0.5rem;
  }
}
