.other-test-administration-wrapper-table {
    background-color: white;
    text-align: left;
    border: 2px solid darkgray;
    width: 25%;
    display: inline-table;
}

.other-test-administration-wrapper-table > tbody > tr > td {
    width: 50%;
    text-align: right;
    padding-right: 32px;
}

.other-test-administration-wrapper-table-not-striped {
    margin-left: auto;
}

.other-test-administration-wrapper-table-correct-answer {
    color: green;
}

.other-test-administration-wrapper-table-incorrect-answer {
    color: red;
}

.other-test-administration-wrapper-button-new {
    display: inline-block;
}
