.FormDropdown {
  position: relative;
  display: flex;
  flex-direction: column;

  & .options {
    position: relative;
    z-index: 2;

    & .FormDropdown-container {
      position: absolute;
    }
  }

  &-container {
    background: #ffffff;
    box-shadow: -6px 6px 20px 0 rgba(14, 30, 37, .2);
    padding: 4px 0;

    & span {
      padding: 4px 16px;
      display: flex;
      flex-direction: column;
      line-height: normal;

      &:hover {
        background: gray;
      }
    }
  }
}
