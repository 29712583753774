.FormCheckboxes {
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 1rem;

  & .choices > div {

    & label {
      display: flex;
      justify-content: space-between;
      width: 100%;

      & input {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }
  }

}
