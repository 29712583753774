@import '../_variables.scss';

.Header {
  display: flex;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  width: 100%;
  z-index: 1;
  align-items: center;

  & > div {
    padding: .5rem;
  }

  & svg {
    cursor: pointer;
  }

  & > * {
    width: calc(100% / 3);
  }

  & .FormSelect {
    text-align: center;
  }

  & .logout {
    text-align: right;
  }

  & .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .FormSelect {
    margin: 0;
  }
}