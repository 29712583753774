.CourseAdministration {

}

.dialog {
  position: absolute;
  inset: 0;
  display: flex;
  place-items: center;
  background: rgba(0, 0, 0, 0.1);

  & > dialog {
    width: 480px;

    & > input {
      width: 100%;
    }

    & > menu {
      text-align: end;
    }
  }
}
