@import "../variables";

.layout-container {
  height: 100vh;
  display: flex;
  flex-direction: column;

  & .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    & .header {
      display: flex;

      & h2 {
        flex: 1;
      }

      & .controls {
        display: flex;
        gap: 1rem;
      }
    }
  }

  & main {
    flex: 1;
    display: flex;
    flex-direction: column;

    & h2 {
      text-align: center;
      font-size: 32px;
      margin-bottom: 2rem;
    }
  }

  // define tables
  table {
    border-collapse: collapse;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
    background-color: transparent;

    & thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
    }

    & tr {
      text-align: left;
      font-size: 18px;

      &:hover {
        background-color: rgba(0, 0, 0, .075);
      }
    }

    & td,
    & th {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }
  }

  .additional-info {
    margin: 8px 0;

    & p {
      background-color: #1a8c72;
      text-align: center;
      padding: 4px 0;
      color: #fff;
      font-size: 20px;
    }
  }

  & .rdg {
    & .filter-cell {
      line-height: 35px;
      padding: 0;

      & input {
        inline-size: 100%;
        padding: 4px;
        font-size: 14px;
        height: 24px;
      }

      > div {
        padding-block: 0;
        padding-inline: 8px;

        &:first-child {
          border-block-end: 1px solid rgba(114, 114, 114, 0.56);
        }
      }
    }

    & .Icon {
      & svg {
        height: 20px;
      }
    }

    & .rdg-header-row .rdg-cell {
      display: flex;
      line-height: 1.5;
      white-space: break-spaces;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;
    }
  }

  & .rdg-row .rdg-cell {
    width: 100%;
    height: 35px;

    & input {
      width: 100%;
    }
  }
}

.modal-dialog {

  & .modal-footer {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      gap: 1rem;
    }
  }
}