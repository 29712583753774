.react-grid-Row .react-grid-Cell {
  background-color: transparent;
}

.react-grid-Canvas .react-grid-Row:hover .react-grid-Cell {
  background-color: brown;
}

.CourseRegistrationAdministration {
  & .controls {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin: 2rem 0;
  }

  & .submenu {
    & .active-background {
      border-radius: 12px;
      position: absolute;
      height: 36px;
      background-color: rgb(177, 224, 105);
      color: white;
      z-index: -1;
      transition: left 0.5s ease, top 0.5s ease, width 0.5s ease; /* Animace přechodu */
    }
  }

  & .course-links {
    display: flex;
    flex-wrap: wrap;

    & label {
      color: black;
      margin-right: 10px;
      cursor: pointer;
      font-size: 22px;
      text-align: center;

      &.selected {
        color: red;
      }
    }

    & label {
      width: 200px;
      white-space: nowrap;
    }
  }

  & .isAlternate {
    &_false {
      color: white;

      &.payment {
        &_true {
          background-color: green;
        }

        &_false {
          background-color: gray;
        }
      }
    }

    &_true {
      background-color: #cee8ff;
    }
  }

  .otherTest td {
    width: 150px;
  }
}