.course-overview-wrapper-payment-config {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.course-overview-wrapper-payment-buttons-container {
  display: flex;
}

.course-overview-wrapper-payment-buttons-container .payment-button:first-child {
  margin-right: 16px;
}

.course-overview-wrapper-payment-buttons-container .payment-button {
  width: 250px;
  height: 60px;
  background-color: #93b24f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
}

.course-overview-wrapper-payment-buttons-container .payment-button.selected {
  background-color: #1a8c72;
  cursor: default;
}

.course-overview-wrapper-payment-config .payment-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.PaymentAdministration-wrapper {
  position: relative;
}

.PaymentAdministration-wrapper-container .account-overview div > label {
  width: 150px;
}

.PaymentAdministration-wrapper-container .account-overview div > span {
  width: 120px;
  display: inline-block;
  text-align: right;
}

.PaymentAdministration-wrapper-container .account-overview div:nth-child(1) > label {
  color: green;
}

.PaymentAdministration-wrapper-container .account-overview div:nth-child(2) > label {
  color: red;
}

.PaymentAdministration-wrapper-container .account-overview div:nth-child(3) > label {
  color: #721c24;
}

.PaymentAdministration-wrapper-container .account-overview {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
}

.PaymentAdministration-wrapper {


  & .rdg {
    & .filter-cell {
      line-height: 35px;
      padding: 0;

      & input {
        inline-size: 100%;
        padding: 4px;
        font-size: 14px;
        height: 24px;
      }

      > div {
        padding-block: 0;
        padding-inline: 8px;

        &:first-child {
          border-block-end: 1px solid rgba(114, 114, 114, 0.56);
        }
      }
    }

    & .Icon {
      & svg {
        height: 20px;
      }
    }

    & .rdg-header-row {
      & .rdg-cell {
        & > span {
          width: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
        }

        & .rdg-header-sort-name {
          width: 100%;
          height: 22px;
          text-align: center;
        }

      }
    }
  }

  & .rdg-row .rdg-cell {
    width: 100%;
    height: 35px;

    & input {
      width: 100%;
    }
  }
}